import {
  selectProcessRunning,
  selectProcessSuccess,
  selectProcessFailed
} from "./selectors";

export const procName = (itemType: string, proc: string) =>
  `${itemType}_${proc}`.toUpperCase();

//running selectors
export const procRunning = (state: any, itemType: string, proc: string) =>
  selectProcessRunning(state, procName(itemType, proc));

export const procLoading = (state: any, itemType: string) =>
  procRunning(state, itemType, "load");

export const procAdding = (state: any, itemType: string) =>
  procRunning(state, itemType, "add");

export const procUpdating = (state: any, itemType: string) =>
  procRunning(state, itemType, "update");

export const procDeleting = (state: any, itemType: string) =>
  procRunning(state, itemType, "remove");

export const procImporting = (state: any, itemType: string) =>
  procRunning(state, itemType, "import");

//success selectors
export const procSuccess = (state: any, itemType: string, proc: string) =>
  selectProcessSuccess(state, procName(itemType, proc));

export const procSuccessLoad = (state: any, itemType: string) =>
  procSuccess(state, itemType, "load");

export const procSuccessAdd = (state: any, itemType: string) =>
  procSuccess(state, itemType, "add");

export const procSuccessUpdate = (state: any, itemType: string) =>
  procSuccess(state, itemType, "update");

export const procSuccessDelete = (state: any, itemType: string) =>
  procSuccess(state, itemType, "remove");

export const procSuccessImport = (state: any, itemType: string) =>
  procSuccess(state, itemType, "import");

//failed selectors
export const procFailed = (state: any, itemType: string, proc: string) =>
  selectProcessFailed(state, procName(itemType, proc));

export const procFailedLoad = (state: any, itemType: string) =>
  procFailed(state, itemType, "load");

export const procFailedAdd = (state: any, itemType: string) =>
  procFailed(state, itemType, "add");

export const procFailedUpdate = (state: any, itemType: string) =>
  procFailed(state, itemType, "update");

export const procFailedDelete = (state: any, itemType: string) =>
  procFailed(state, itemType, "remove");

export const procFailedImport = (state: any, itemType: string) =>
  procFailed(state, itemType, "import");

//misc
export function firstUpperCase(s: string): string {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
}

/**
 * Created reducer.
 * @param initialState reducer initial state
 * @param handlers reducer handlers
 *
 */
export function createReducer(initialState: any, handlers: any) {
  return function reducer(state = initialState, action: any) {
    // eslint-disable-next-line no-prototype-builtins
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };
}
