import { createDckReducer } from "dck-redux";
import { createBrowserHistory } from "history";
import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import { createReduxHistoryContext } from "redux-first-history";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";

import { processMiddleware } from "srx-shared";

import rootSaga from "@sagas";

import ProcessTypes from "./processes/types";

const browserHistory = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: browserHistory
  });

const sagaMiddleware = createSagaMiddleware();
const middlewares = [processMiddleware(), sagaMiddleware, routerMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

process.env.REACT_APP_LOGGER === "console" &&
  middlewares.push(
    createLogger({
      collapsed: (getState, action, logEntry) => !logEntry.error
    })
  );

export function configureStore(initialState) {
  return createStore(
    combineReducers({
      dck: createDckReducer([], ProcessTypes),
      router: routerReducer
    }),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
}

export const store = configureStore();

export const history = createReduxHistory(store);

sagaMiddleware.run(rootSaga);
